.Footer-container{
    position: relative;

}
.Footer-container>hr{
    border: 1px solid var(--lightgray);

}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;

}
.social-link{
    display: flex;
    gap: 4rem;
}
.social-link>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
width: 10rem;

}

.blur-f-1{
    bottom:0;
    right: 15%;
    width: 30rem;
    height: 12rem;
    filter: blur(180px);
    background:red;
}
.blur-f-2{
    bottom: 0;
    left: 15%;
    width: 30rem;
    height: 12rem;
    filter: blur(180px);
    background: rgb(255, 115, 0);
}

.Footer-container>span{
    justify-content: center;
    color: white;
   font-weight: bold;
  
   align-items: center;
   width: 2rem;
   height: 2rem;
   padding-left: 40rem;
   font-size: large;
   font-family: unset;
   font-size: x-large;
  
}